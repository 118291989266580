<template>
  <div>
    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
    <span class="leading-none font-medium">Hotel Images</span>
    <vs-button icon="save" type="border" size="small" @click="addMoreHotelImage" class="plus-icons" color="primary"> Add Image</vs-button>
    <div v-if="showAddForm" class="images-comps">
        <div class="images-comp">
            <file-pond
                name="test"
                ref="pond"
                label-idle="Drop image here..."
                :allow-multiple="false"
                instantUpload="false"  
                allowImagePreview="true"
                allowFileMetadata="true"
                allowImageSizeMetadata="true"
                accepted-file-types="image/jpeg, image/png"
                :allowImageTransform="false"
                @addfile="addNewHotelImage" />
                <vs-input 
                class="w-full mt-4" 
                label="Alt Text" 
                v-model="newImage.alt"/>
            </div>
            <div class="demo-alignment">
                <vs-button
                  icon="save" 
                  type="border"
                  size="small" 
                  @click="saveNewHotelImage"
                  color="primary">
                Save
                </vs-button> 
                
              <vs-button icon="delete" type="border" size="small" @click="hideAddForm" class="cross-icons" v-if="showAddForm" color="danger">Remove</vs-button> 
          </div>
      </div> 
    <div v-for="(image, index) in hotelImages" :key="index" class="images-comp">
        <div  class="images-comps">
            <file-pond
                name="test"
                ref="pond"
                label-idle="Drop image here..."
                :allow-multiple="false"
                instantUpload="false"  
                allowImagePreview="true"
                allowFileMetadata="true"
                :fileMetadataObject="{index: index}"
                allowImageSizeMetadata="true"
                accepted-file-types="image/jpeg, image/png"
                :allowImageTransform="false"
                :files="image.source"
                :disabled="editImage.id != image.imageId"
                @addfile="handleFileChangeUpdate" />
            <vs-input 
                class="w-full mt-4" 
                label="Alt Text" 
                :danger="validation.alt?true:false"
                :danger-text="validation.alt?validation.alt[0]:''"
                :value="(editImage.id == image.imageId && editImage.alt != '') ? editImage.alt : image.alt"
                :disabled="editImage.id != image.imageId"
                @input="updateAltForm({key: 'alt', value: $event, index: index})" />
        </div>
        <div class="demo-alignment">
            <vs-button icon="save" v-if="editImage.id == image.imageId" type="border" size="small" @click="updateHotelImage" color="primary">Update</vs-button>
            <vs-button color="primary" v-if="editImage.id != image.imageId" size="small" type="border" icon-pack="feather" icon="icon-edit" @click="editHotelImage(image.imageId)" >Edit</vs-button>
            <vs-button color="danger" size="small" type="border" icon-pack="feather" icon="icon-archive" @click="confirmDeleteHotelImage(image.imageId)">Delete</vs-button> 
        </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

import vueFilePond from 'vue-filepond';
import {storeImage, updateImage, destroyImage} from '../../../../services/image';
import {HOTEL} from '../../../../constant/entity-identifier';

import loaderMixin from '../../../../mixins/loaderMixin';
// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileMetadata );

export default {
  components: {
    FilePond
  },
  data: () => {
    return{
      imageId: '',
      showXCircleIcon: false,
      imageIndex: '',
      showAddForm: false,
      editImage: {
        id:'',
        image: '',
        alt: ''
      },
      newImage: {
        entityId:'',
        image: '',
        alt: ''
      },
    }
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'hotel/getFormObj',
        hotelImages: 'hotel/editHotelImages',
        validation: 'validation/getValidationErrors'
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'hotel/updateForm',
      fetchAndSetHotelById: 'hotel/fetchAndSetHotelById',
    }),

    hideAddForm(){
        this.newImage.entityId = '';
        this.newImage.image = '';
        this.newImage.alt = '';
        this.showAddForm = false;
    },

    addMoreHotelImage(){
        this.showAddForm = true;
        this.newImage.entityId = this.form.hotelId;
    },
    
    addNewHotelImage(file_name, files) {
        this.newImage.image = files.file;
    },

    prepareForm() {
      let formData = new FormData();
      formData.append("entity_id", this.newImage.entityId);
      formData.append("entity", HOTEL);
      formData.append("images[]", this.newImage.image);
      formData.append("alt[]", this.newImage.alt);
      return formData;
    },

    saveNewHotelImage(){
      
        this.openLoading();
        storeImage(this.prepareForm()).then((response) => {
          if(response.data.code === 200){
                this.closeLoading();
                this.showAddForm = false;
                this.newImage.image = '';
                this.newImage.alt = '';
                this.editHotelRecord();
                 this.$vs.notify({ 
                    text: 'Hotel Images Added successfully.',
                    title: 'Hotel Images Added',
                    color: 'success',
                    position: 'top-right'
                });
            }
      }).catch(() => this.closeLoading());
    },
    
    handleFileChangeUpdate(file_name, files) {
        this.editImage.image = files.file;
    },

    updateAltForm(alt){
      this.editImage.alt = alt.value;
    },

    editHotelImage($id){
        this.editImage.id = $id;
        this.editImage.alt = '';
        this.editImage.image = '';
    },

    
    updatePrepareForm() {
      let formData = new FormData();
      formData.append("_method", 'POST');
      formData.append("entity_id", this.form.hotelId);
      formData.append("entity", HOTEL);
      formData.append("images[]", this.editImage.image);
      formData.append("alt[]", this.editImage.alt);
      return formData;
    },
    updateHotelImage() {
      this.openLoading();
      updateImage(
        this.editImage.id,
        this.updatePrepareForm()
      ).then((response) => {
        if(response.data.code === 200){
            this.closeLoading();
            this.editImage.id = '';
            this.editImage.alt = '';
            this.editImage.image = '';
            this.editHotelRecord();
            this.$vs.notify({ 
                    text: 'Hotel Images Updated successfully.',
                    title: 'Hotel Images Updated',
                    color: 'success',
                    position: 'top-right'
                });
          }
      }).catch(() => this.closeLoading());
    },

    confirmDeleteHotelImage($id){
         this.imageId = $id;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `You are about to delete image with id: "${$id}"`,
        accept: this.deleteHotelImage,
        acceptText: "Delete"
      });
    },

    editHotelRecord(){
      this.fetchAndSetHotelById(this.form.hotelId).catch((r) => console.log(r));
    },

    deleteHotelImage(){
        destroyImage(this.imageId).then((response) => {
            if(response.data.code === 204){
                this.editHotelRecord();
                this.$vs.notify({ 
                    text: 'Hotel Images Deleted successfully.',
                    title: 'Hotel Images Deleted',
                    color: 'success',
                    position: 'top-right'
                });
            }
        });
    }
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
  .plus-icons{
    float: right;
    cursor: pointer;
    color: #0c78e7;
    padding-left: 10px;
  }
  .cross-icons{
    float: right;
    cursor: pointer;
    color: rgb(231, 12, 12);
  }
  .images-comps{
    margin-top: 35px;
  }
}
</style>
