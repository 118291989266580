<template>
<div id="page-user-list">
  <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <HotelForm></HotelForm>
          </div>
          <div class="vx-card p-6 mb-6">
            <SeoInfo></SeoInfo>
          
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <div class="vx-card p-6 mb-6">
              <Geo></Geo>
          </div>
          <div class="vx-card p-6 mb-6">
            <EditHotelImage></EditHotelImage>
          </div>
        </div>
       </div>
        <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <SettingInfo></SettingInfo>
 
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
</div>
</template>


<script>
import vSelect from 'vue-select'
import HotelForm from './common/HotelForm'
import SeoInfo from './common/SeoInfo'
import Geo from './common/Geo'
import EditHotelImage from './common/EditHotelImage'
import SettingInfo from './common/SettingInfo'
import loaderMixin from '../../../mixins/loaderMixin';
import {HOTEL} from '../../../constant/entity-identifier';
import { mapGetters, mapActions } from 'vuex'

import {storeImage} from '../../../services/image';

export default {
  components: {
    vSelect,
    HotelForm,
    SeoInfo,
    Geo,
    EditHotelImage,
    SettingInfo,
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'hotel/getFormObj'
    }),
    getHotelId() {
        return this.$route.params.hotelId;
    },
  },
  created() {
      this.clearForm();
      this.clearValidationErrors();
     this.openLoading();
     this.fetchAndSetHotelById(this.getHotelId).then(() => {
       this.closeLoading();
     }).catch((r) => this.closeLoading());
    },
  methods: {
    ...mapActions({
      updateHotelAction: 'hotel/updateHotelAction',
      fetchAndSetHotelById: 'hotel/fetchAndSetHotelById',
      clearForm: 'hotel/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),

    submitForm() {
      this.openLoading();
      this.updateHotelAction({
        hotelId: this.getHotelId,
        data: this.form
      }).then(response => {  
        if(response.status === 202) {
       this.closeLoading();
        this.$vs.notify({ 
            text: 'Hotel updated successfully.',
            title: 'Hotel Updated',
            color: 'success',
            position: 'top-right'
        });
        this.$router.push('/hotels');}
      }).catch(() => this.closeLoading());
    }
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
